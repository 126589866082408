<div class="container-fluid bg purchase-summary-main">
  <app-admin-header></app-admin-header>
  <div class="container-fluid box-crunch">
    <div class="crunch-box-bar">
      <div class="crunch-head">User Management</div>
      <div class="returncrunch-right">
        <span style="cursor: pointer;" class="management-label" [routerLink]="['/admin/usermanagement']">User
          Management</span>
        <span style="cursor: pointer;" class="admin-crunch" [routerLink]="['/admin/crunchboxtools']"> CrunchBox
          Tools</span>
      </div>
    </div>
  </div>
  <div class="container-fluid admin-newregistrationrequests">
    <div class=" user-registration-request">
      <div class="mr-auto all-registration">
        <label class="col-md-6 float-left"> All Registration Requests</label>
        <div class="col-md-6 float-right-n" [routerLink]="['/admin/usermanagement']"><button type="button"
            class="btn btn-outline-primary new-registration-request ">New Registration Requests</button>
          <button type="button" class="btn btn-primary all-registration-request" [routerLink]="['/admin/user-list']">All
            Registration Requests</button>
        </div>
      </div>
    </div>
    <div class="d-flex user-registration-request2">
      <i class="fa fa-search cr-search"></i><input class="form-control mr-sm-2 search mr-auto cr-search-input"
        [(ngModel)]="searchKey" (ngModelChange)="filterTable($event)" type="search" placeholder="Search"
        aria-label="Search">
    </div>
    <table class="table newregistration-table">
      <thead class="thead">
        <tr class="newregistration-head ">
          <th class="allreg-name">Name</th>
          <th>Email</th>
          <th>Status</th>
          <th>Institution </th>
          <th>View</th>
          <th class="removebtn">Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tool of tools | paginate: { itemsPerPage: 10, currentPage: p }">
          <td>{{tool.first_name}} {{tool.last_name}}</td>
          <td>{{tool.email}}</td>
          <td>{{tool.status}}</td>
          <td>{{tool.institution}}</td>
          <td><a class="newreg-viewdetails" [routerLink]="['/admin/user-details',tool.id]">Details</a></td>
          <td><img src="../../assets/images/trash-alt.png" class="deleteicon" (click)="deleteUser(tool.id)"
              style="cursor: pointer;"></td>
        </tr>

      </tbody>

    </table>
    <div>

      <pagination-controls (pageChange)="p = $event"></pagination-controls>
      <!-- <jw-pagination [items]="tools" (changePage)="onChangePage($event)"></jw-pagination> -->
    </div>

  </div>
</div>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" #cd>
  <p-footer>
    <button type="button" pButton class="yesbtn" icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    <button type="button" class="nobtn" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
  </p-footer>
</p-confirmDialog>