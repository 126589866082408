<div class="container-fluid bg purchase-summary-main">
  <app-admin-header></app-admin-header>
  <div class="container-fluid box-crunch">
    <div class="crunch-box-bar">
      <div class="crunch-head"> Change Password</div>
    </div>
    <div class="container-fluid changepassword">
      <!-- <div class="admin-password"> Change Password</div> -->
      <!-- <div class="row pass-sec">
    <div class="col-md-6">
      <label class="label-placeholder prochangepwd-label" for="fname">Current Password</label>
    </div>
  </div> -->
      <div class="sub-section" id="changepassword-section">
        <h2 class="changepassword-head">Change Password</h2>
        <form [formGroup]="changeForm" (ngSubmit)="onSubmit()" novalidate [mustMatch]="['newpassword', 'confirmpass']">
          <div class="row pass-sec">
            <div class="col-md-12">
              <label class="label-placeholder" for="fname">Current Password</label>
              <input [type]="showNewPassword ? 'text' : 'password'" id="currentPassword"
                class="form-control fadeIn second" name="currentpassword" formControlName="currentpassword"
                placeholder="" [ngClass]="{ 'is-invalid': submitted && f.currentpassword.invalid }">
              <i alt="show" class="eye-show reset-eye-one" (click)="showNewPassword = !showNewPassword"
                [class.hide]="!showNewPassword"> </i>
              <i alt="hide" class="eye-hide reset-eye-one" (click)="showNewPassword = !showNewPassword"
                [class.hide]="showNewPassword"> </i>
              <div *ngIf="submitted && f.currentpassword.invalid" class="invalid-error">
                <div *ngIf="f.currentpassword.errors.required">Current Password is required</div>
                <div *ngIf="f.currentpassword.errors.shouldBe1234">Old Password is not valid.</div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="label-placeholder newpassword-space" for="lname">New Password</label>
              <input [type]="showPassword ? 'text' : 'password'" id="newpassword" name="newpassword"
                formControlName="newpassword" class="fadeIn form-control second" minlength="6" placeholder=""
                [ngClass]="{ 'is-invalid': submitted && f.currentpassword.invalid }">
              <i alt="show" class="eye-show reset-eye-two" (click)="showPassword = !showPassword"
                [class.hide]="!showPassword"></i>
              <i alt="hide" class="eye-hide reset-eye-two" (click)="showPassword = !showPassword"
                [class.hide]="showPassword"></i>
              <div *ngIf="submitted && f.newpassword.invalid" class="invalid-error">
                <div *ngIf="f.newpassword.errors.required">New Password is required</div>
                <div *ngIf="f.newpassword.errors.minlength">Minimum length is 6 characters</div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="label-placeholder confirmpassword-space" for="lname">Confirm Password</label>
              <input [type]="showConfirmPassword ? 'text' : 'password'" id="confirmpass" name="confirmpass"
                formControlName="confirmpass" class="form-control fadeIn second" placeholder=""
                [ngClass]="{ 'is-invalid': submitted && f.currentpassword.invalid }">
              <i alt="show" class="eye-show reset-eye-three" (click)="showConfirmPassword = !showConfirmPassword"
                [class.hide]="!showConfirmPassword"></i>
              <i alt="hide" class="eye-hide reset-eye-three" (click)="showConfirmPassword = !showConfirmPassword"
                [class.hide]="showConfirmPassword"></i>
              <div *ngIf="submitted && f.confirmpass.invalid" class="invalid-error">
                <div *ngIf="f.confirmpass.errors.required">Confirm Password is required</div>
                <div *ngIf="f.confirmpass.errors.mustMatch">Passwords must match</div>
              </div>
            </div>
            <div class="col-md-6">
              <button type="submit" class="sub-btn">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>